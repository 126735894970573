import { ExperimentFile, ExperimentFileStatus } from "../../types/ExperimentFile";

export const getFileReader = (onLoadCallback) => {
  return async (file) => {
    const reader = new FileReader();
    reader.onload = async function (e) {
      const { name, size, type, webkitRelativePath } = file;
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      const b64file = e.target.result.toString().split(";base64,")[1];
      const newFile: ExperimentFile = {
        meta: { name, size, type, webkitRelativePath },
        content: b64file,
        analyzeStatus: ExperimentFileStatus.Pending,
      };
      onLoadCallback((prev) => [...prev, newFile]);
    };
    reader.readAsDataURL(file);
  };
};

export function mapToObject(map) {
  const obj = {};
  map.forEach((value, key) => {
    if (value instanceof Map) {
      obj[key] = mapToObject(value); // Recursively convert nested Maps
    } else {
      obj[key] = value;
    }
  });
  return obj;
}
