import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import TemplateImage from "../assets/svg/template-generator.svg";
import CreateExperimentDialog from "./Experiment/CreateExperimentDialog";
import UploadDialog from "./FileUploader/UploadDialog";
import vars from "../styles/variables";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addFiles as addExperimentFiles, selectFile } from "../store/reducers/experimentFile";

const { primaryFont, secondaryFont, dialogTitleColor, dialogDescColor, dialogBorderColor } = vars;

export default function Dashboard(props) {
  const { files, setFiles } = props;
  const classes = {
    content: {
      padding: "1.5rem 2.5rem 2.5rem",
      height: "100%",
    },
    info: {
      textAlign: "center",
      maxWidth: "26.25rem",
      minHeight: "26.25rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      flexGrow: 1,

      "&:not(:first-of-type)": {
        marginLeft: "4rem",
        paddingLeft: "4rem",
        borderLeft: `0.0625rem solid ${dialogBorderColor}`,
      },

      "& > svg": {
        marginBottom: "1rem",
      },

      "& > .MuiTypography-root": {
        marginBottom: "0.75rem",
        fontFamily: secondaryFont,
        fontSize: "1.5rem",
        lineHeight: "2rem",
        color: dialogTitleColor,
      },

      "& > .MuiTypography-body2": {
        marginBottom: "1.5rem",
        fontFamily: primaryFont,
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        color: dialogDescColor,
      },
    },
  };
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCloseUploadDialog = (uploads: any[]) => {
    if (uploads.length > 0) {
      dispatch(addExperimentFiles(uploads));
      dispatch(selectFile(uploads[0]));
      navigate("/analysis");
    }
    setOpenUploadDialog(false);
  };
  const handleClose = () => {
    setOpenCreateDialog(false);
  };
  return (
    <>
      <Box sx={classes.content}>
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          <Box sx={classes.info}>
            <TemplateImage />
            <Typography>I don’t have standardized data</Typography>
            <Typography variant="body2">In order to proceed to the analysis, we need you to use a standardized file that we’ll generate for you.</Typography>
            <Button variant="contained" color="secondary" onClick={() => setOpenCreateDialog(true)}>
              Open the sample list generator
            </Button>
          </Box>

          <Box sx={classes.info}>
            <TemplateImage />
            <Typography>I already have standardized data</Typography>
            <Typography variant="body2">If you already have a standardized data file filled, you can directly upload it.</Typography>
            <Button variant="contained" color="secondary" onClick={() => setOpenUploadDialog(true)}>
              Upload a data file
            </Button>
          </Box>
        </Box>
      </Box>
      <CreateExperimentDialog open={openCreateDialog} setOpen={setOpenCreateDialog} handleClose={handleClose} />
      <UploadDialog open={openUploadDialog} handleClose={handleCloseUploadDialog} files={files} setFiles={setFiles} />
    </>
  );
}
