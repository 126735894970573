import { configureStore } from "@reduxjs/toolkit";
import experimentFileReducer from "./reducers/experimentFile";
import licensingReducer from "./reducers/licensing";

export default configureStore({
  reducer: {
    experimentFiles: experimentFileReducer,
    licensing: licensingReducer,
  },
});
