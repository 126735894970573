export const hiddenCols = {
  experimentSheet: ["H", "I", "J", "L"],
};

export const range = function (size, startAt = 0) {
  return [...Array(parseInt(size)).keys()].map((i) => i + startAt);
};

// Shamelessly copied from stackoverflow
export const cartesian = function (...args) {
  const r = [];
  const max = args.length - 1;
  const helper = function (arr, i) {
    for (let j = 0, l = args[i].length; j < l; j++) {
      const a = arr.slice(0); // clone array
      a.push(args[i][j]);
      if (i === max) {
        r.push(a);
      } else {
        helper(a, i + 1);
      }
    }
  };

  helper([], 0);
  return r;
};

export const groupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};
