import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import React from "react";
import UploadIcon from "../../assets/svg/upload.svg";
import { FileUploader } from "react-drag-drop-files";
import FingerprintWidget from "../License/FingerprintWidget";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";

const classes = {
  root: {
    background: "transparent",
    border: `0`,
    borderRadius: "0.5rem",
    cursor: "pointer",
    lineHeight: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& p": {
      marginTop: "0.25rem",
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: "1.25rem",

      "& strong": {
        fontWeight: 600,
      },
    },
  },

  icon: {
    marginRight: ".5rem",
  },
};

const LicenseUploadDialog = (props) => {
  const { open, setOpen, handleFileChange, handleUpload, selectedFile } = props;

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "45rem",
        },
      }}
    >
      <DialogContent
        sx={{
          height: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          padding: "1rem",
        }}
      >
        <Box pt="1rem" pb="1rem" display="flex" alignItems="center" justifyContent="space-around" width={1}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <FingerprintWidget />
            <Typography component="strong" mt=".5rem">
              Generate unique code to be <br /> shared with Javelin team
            </Typography>
          </Box>
          <FileUploader handleChange={handleFileChange} onTypeError={(err) => console.log(err)} name="file" maxSize={10} classes="licence-upload">
            <Box sx={classes.root}>
              <Box sx={classes.icon}>
                <DownloadForOfflineOutlinedIcon />
              </Box>
              <Typography>
                <Typography component="strong">Click to upload</Typography> or drop your file here
              </Typography>
            </Box>
          </FileUploader>
        </Box>

        {selectedFile && (
          <Box p={"1rem"} display="flex" width={1}>
            <Typography variant="body2" mt={2}>
              Selected file: {selectedFile.name}
            </Typography>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          disabled={!selectedFile}
          onClick={() => {
            handleUpload();
            setOpen(false);
          }}
        >
          <UploadIcon className="left-icon" />
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LicenseUploadDialog;
