import { List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { FileIcon } from "../../shared/Icons";
import DataFile from "../../assets/svg/data-file.svg";
import vars from "../../styles/variables";
import { FileUploader } from "react-drag-drop-files";
import DELETE_ICON from "../../assets/svg/delete.svg";
import { getFileReader } from "./utils";

const { secondaryBg, primaryTextColor, primaryColor, dialogBorderColor, placeholderColor, dialogBgColor, progressBorderColor, labelColor } = vars;

const fileTypes = ["XLS", "XLSX"];

const FileUpload = (props) => {
  const { files, setFiles, multiple } = props;
  const fileReader = getFileReader(setFiles);
  const [key, setKey] = useState(0);

  const readFiles = async (files) => {
    if (multiple) {
      for (let i = 0; i < files.length; i++) {
        await fileReader(files.item(i));
      }
      return;
    }
    await fileReader(files);
  };

  const classes = {
    root: {
      background: secondaryBg,
      border: `0.0625rem dashed ${placeholderColor}`,
      borderRadius: "0.5rem",
      height: "11.875rem",
      cursor: "pointer",
      lineHeight: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",

      "& p": {
        marginTop: "0.25rem",
        fontWeight: 400,
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        color: primaryTextColor,

        "& strong": {
          color: primaryColor,
          fontWeight: 600,
        },
      },

      "& svg": {
        display: "block",
        fill: dialogBorderColor,
      },
    },

    icon: {
      width: "2.5rem",
      height: "2.5rem",
      background: dialogBorderColor,
      border: `0.375rem solid ${dialogBgColor}`,
      borderRadius: "1.75rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "0.75rem",
    },

    list: {
      "& .MuiListItem-root": {
        border: `1px solid ${progressBorderColor}`,
        padding: "1rem",
        borderRadius: "0.75rem",

        "&:not(:first-of-type)": {
          marginTop: "0.75rem",
        },

        "& .MuiTypography-root": {
          fontSize: "0.875rem",
          lineHeight: "1.25rem",
          color: labelColor,
        },

        "& span": {
          color: primaryTextColor,
          fontWeight: 500,
        },

        "& p": {
          fontWeight: 400,
          marginBottom: 0,
        },
      },
    },
  };

  const removeFile = (index) => {
    setFiles(files.filter((file, i) => i !== index));

    // Update key to force re-render of FileUploader
    setKey((prevKey) => prevKey + 1);
  };

  return (
    <>
      <FileUploader key={key} multiple={multiple} handleChange={readFiles} onTypeError={(err) => console.log(err)} name="file" types={fileTypes} maxSize={10}>
        <Box sx={classes.root}>
          <Box sx={classes.icon}>
            <FileIcon />
          </Box>
          <Typography>
            <Typography component="strong">Click to upload</Typography> or drop your file here
          </Typography>
          <Typography>XLS or XLSX (max. 10 Mb)</Typography>
        </Box>
      </FileUploader>

      {files?.length > 0 && (
        <Box mt={2} sx={classes.list}>
          <List>
            {files.map((file, index) => (
              <ListItem key={`file_${file.meta.name}_${index}`}>
                <ListItemIcon>
                  <DataFile />
                </ListItemIcon>
                <ListItemText primary={file.meta.name} secondary={`${Math.round(file.meta.size / 1000)} KB – 100% uploaded`} />
                <ListItemIcon sx={{ cursor: "pointer" }} onClick={() => removeFile(index)}>
                  {/* <CheckBoxFill /> */}
                  <DELETE_ICON />
                </ListItemIcon>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </>
  );
};

export default FileUpload;
