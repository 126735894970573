interface LocalStorageService {
  set(key: string, value: any): void;
  get(key: string): any;
  remove(key: string): void;
  clear(): void;
}

const localStorageService: LocalStorageService = {
  set: (key, value) => {
    if (!key || value === undefined) {
      return;
    }

    if (typeof value === "object") {
      value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
  },

  get: (key) => {
    if (!key) {
      return null;
    }

    const value = localStorage.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  },

  remove: (key) => {
    if (!key) {
      return;
    }
    localStorage.removeItem(key);
  },

  clear: () => {
    localStorage.clear();
  },
};

export default localStorageService;
