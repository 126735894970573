import {
  CHIP_DEFAULT_FLOW_RATE,
  CHIP_DEFAULT_HEP_DONOR,
  CHIP_DEFAULT_HEP_NUM,
  CHIP_DEFAULT_MEDIA_TYPE,
  CHIP_INPUTS,
  COCKTAIL_INPUTS,
  EXPERIMENT_INPUTS,
} from "../../../shared/constant";
import { preIncubationDefaults, victimDrugDefaults, inhibitionCocktailDefaults } from "./defaultValues";

const populateDDIFromTemplate = (template, subtype) => {
  if (!template) {
    return null;
  }

  const perpetratorDrugs = [...new Set(template.maindata.filter((e) => e.perpetrator_drug.value.toLowerCase() !== "na").map((e) => e.perpetrator_drug.value))];

  const solutionsData = template.maindata.reduce((acc, item) => {
    if (!acc[item.working_solution.value]) {
      acc[item.working_solution.value] = [];
    }
    acc[item.working_solution.value].push(item);
    return acc;
  }, {});

  const workingSolutionEntries = Object.values(solutionsData);

  const experiment = {
    [EXPERIMENT_INPUTS.studyType]: subtype,
    [EXPERIMENT_INPUTS.id]: template.expinfo.infos.experiment_id,
    [EXPERIMENT_INPUTS.chips]: template.expinfo.chips.map((t) => templateToChipDDI(t)),
    [EXPERIMENT_INPUTS.cocktails]:
      workingSolutionEntries.length > 0 ? workingSolutionEntries.map((e) => templateEntriesToVictimDrug(e)) : [{ ...inhibitionCocktailDefaults }],
    [EXPERIMENT_INPUTS.inducerdrugs]: perpetratorDrugs.map((p) => templateToInducerDrug(p)),
  };

  const isChipOnly = experiment.cocktails.some((e) => e.nsbCondition?.[COCKTAIL_INPUTS.linkedChips]?.length > 0);

  return { experiment, isChipOnly };
};

const templateToInducerDrug = (inducer) => {
  return {
    [COCKTAIL_INPUTS.perpetrator]: {
      ...preIncubationDefaults,
      [COCKTAIL_INPUTS.drugName]: inducer,
    },
  };
};

const templateEntriesToVictimDrug = (entries) => {
  const perpetrator = entries.find((e) => e.perpetrator_drug.value.toLowerCase() !== "na").perpetrator_drug.value;
  const victimDrugs = [...new Set(entries.map((e) => e.victim_drug.value))];

  const conditions = entries.filter((e) => e.condition.value.toLowerCase() !== "nsb" && e.condition.value.toLowerCase() !== "working solution");
  const nsbConditions = entries.filter((e) => e.condition.value.toLowerCase() === "nsb");

  return {
    [COCKTAIL_INPUTS.metabolismCondition]: {
      ...extractDetails(conditions),
      ...extractLinkedChips(conditions),
    },
    [COCKTAIL_INPUTS.nsbCondition]: {
      ...extractNsbLinkedChips(nsbConditions),
      ...extractDetails(conditions),
    },
    [COCKTAIL_INPUTS.victimDrug]: {
      ...victimDrugDefaults,
      [COCKTAIL_INPUTS.preIncubationDrugName]: [perpetrator],
      [COCKTAIL_INPUTS.drugName]: victimDrugs,
    },
  };
};

const extractDetails = (conditions) => {
  if (conditions.length === 0) {
    return null;
  }

  return {
    [COCKTAIL_INPUTS.timepoints]: [...new Set(conditions.map((e) => e.sample_time.value))],
    [COCKTAIL_INPUTS.sampleVolume]: conditions[0].sample_volume.value,
    [COCKTAIL_INPUTS.technicalReplicates]: Math.max(...conditions.map((e) => e.technical_replicate.value)),
    [COCKTAIL_INPUTS.locations]: [...new Set(conditions.map((e) => e.sample_location.value))],
  };
};

const extractLinkedChips = (conditions) => {
  if (conditions.length === 0) {
    return null;
  }

  return {
    [COCKTAIL_INPUTS.iiLinkedChips]: [...new Set(conditions.filter((e) => e.condition.value.toLowerCase() !== "control").map((e) => e.chip_id.value))],
    [COCKTAIL_INPUTS.vehicleControllinkedChips]: [
      ...new Set(conditions.filter((e) => e.condition.value.toLowerCase() === "control").map((e) => e.chip_id.value)),
    ],
  };
};

const extractNsbLinkedChips = (conditions) => {
  if (conditions.length === 0) {
    return null;
  }

  return {
    [COCKTAIL_INPUTS.linkedChips]: [...new Set(conditions.filter((e) => e.condition.value.toLowerCase() === "nsb").map((e) => e.chip_id.value))],
  };
};

const templateToChipDDI = (chip) => {
  return {
    [CHIP_INPUTS.id]: chip.chip_id.value || "",
    [CHIP_INPUTS.flowRate]: chip.flow_rate.value ? `${chip.flow_rate.value}` : CHIP_DEFAULT_FLOW_RATE,
    [CHIP_INPUTS.totalVol]: chip.total_volume.value ? `${chip.total_volume.value}` : CHIP_DEFAULT_FLOW_RATE,
    [CHIP_INPUTS.hepNum]: chip.hepatocyte_number.value ? `${chip.hepatocyte_number.value}` : CHIP_DEFAULT_HEP_NUM,
    [CHIP_INPUTS.hepDonor]: chip.hepatocyte_donor.value ? `${chip.hepatocyte_donor.value}` : CHIP_DEFAULT_HEP_DONOR,
    [CHIP_INPUTS.lungVol]: chip.final_lung_volume.value ? `${chip.final_lung_volume.value}` : "",
    [CHIP_INPUTS.mediaType]: chip.media_type ? chip.media_type.value : CHIP_DEFAULT_MEDIA_TYPE,
    [CHIP_INPUTS.comment]: chip.comments.value || "",
  };
};

export { populateDDIFromTemplate };
