export const parseTraceback = (traceback) => {
  const lines = traceback.split("\n");
  if (lines.length === 1) {
    return {
      "Error Message": lines[0],
    };
  } else {
    let faultyFile = "";
    let faultyLine = "";
    let errorMessage = "";

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];

      // Match lines with 'File', 'line', and 'in'
      const fileLineMatch = line.match(/File "(.+)", line (\d+), in (.+)/);
      if (fileLineMatch) {
        faultyFile = fileLineMatch[1];
        faultyLine = fileLineMatch[2];
      }

      // Match the actual error message starting with `raise " and ending with closing "
      const raiseErrorMatch = line.match(/raise "(.*)"/);
      if (raiseErrorMatch) {
        errorMessage = raiseErrorMatch[1];
      }
    }

    if (!errorMessage) {
      // Try to capture the last line as the error message if specific error match didn't succeed
      errorMessage = lines[lines.length - 1].trim();
    }

    return {
      File: faultyFile,
      Line: faultyLine,
      "Error Message": errorMessage,
    };
  }
};
