import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Box } from "@mui/material";

export default function SubHeaderDrugTable({ headers, rows }) {
  const defaultColDef = {
    tooltipShowDelay: 5000,
    tooltipHideDelay: 100,
    resizable: true,
    sortable: true,
    initialWidth: 70,
  };
  return (
    <Box className="ag-theme-alpine" style={{ height: 400, width: "100%" }}>
      <AgGridReact columnDefs={headers} rowHeight={52} rowData={rows} suppressAutoSize defaultColDef={defaultColDef} multiSortKey={"ctrl"} />
    </Box>
  );
}
