const initListeners = (setDeferredPrompt) => {
  window.addEventListener("beforeinstallprompt", (e) => {
    // Save the event because you’ll need to trigger it later.
    setDeferredPrompt(e);
    // Show your customized install prompt for your PWA
    console.log("BeforeInstallPromptEvent fired");
  });
};

export default initListeners;
