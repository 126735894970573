export enum ExperimentFileStatus {
  Analyzed = "ANALYZED",
  Error = "ERROR",
  Pending = "PENDING",
  Running = "RUNNING",
  XlsxImported = "RUNNING-IMPORTED",
}

export interface ExperimentFile {
  meta: {
    name: string;
    size: number;
    type: string;
    webkitRelativePath: string;
  };
  content: any;
  analyzeStatus: ExperimentFileStatus;
  data?: string;
  results?: string;
  selected?: boolean;
  includeRejected?: boolean;
  experimentType?: string;
}
