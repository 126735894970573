import { Workbox } from "workbox-window";
import { startPythonWebWorker } from "./pythonWorker";

// startPythonWebWorker();

export default function registerServiceWorker() {
  // Check if the serviceWorker Object exists in the navigator object ( means if browser supports SW )
  if ("serviceWorker" in navigator) {
    // Check if a service worker is already controlling the page

    navigator.serviceWorker.ready.then(() => {
      startPythonWebWorker();
    });

    if (!navigator.serviceWorker.controller) {
      const wb = new Workbox("sw.js");

      wb.addEventListener("installed", (event) => {
        if (event.isUpdate) {
          if (confirm("New app update is available! Click OK to refresh.")) {
            window.location.reload();
          }
        }
      });

      wb.register();
    } else {
      console.log("Service worker already registered and controlling the page.");
    }
  }
}
