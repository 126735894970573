import { createSlice } from "@reduxjs/toolkit";
import { ExperimentFileStatus } from "../../types/ExperimentFile";

export const ActiveExperimentType = {
  HC: "HC",
  DDI: "DDI",
};

const initialState = [];

const experimentFileSlice = createSlice({
  name: "experimentfiles",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    addFiles: (state, action) => {
      return [...state, ...action.payload];
    },
    updateFile: (state, action) => {
      return state.map((file) => (file.meta.name === action.payload.meta.name ? action.payload : file));
    },
    deleteFile: (state, action) => {
      return state.filter((file) => file.meta.name !== action.payload.meta.name);
    },
    selectFile: (state, action) => {
      return state.map((file) => {
        return { ...file, selected: file.meta.name === action.payload.meta.name };
      });
    },
    rerunAnalysis: (state, action) => {
      return state.map((file) =>
        file.meta.name === action.payload.meta.name
          ? { ...file, analyzeStatus: ExperimentFileStatus.Pending, includeRejected: Boolean(action.payload?.includeRejected) }
          : file,
      );
    },
    updateExperiment: (state, action) => {
      return state.map((file) => (file.meta.name === action.payload.meta.name ? { ...file, ...action.payload } : file));
    },
  },
});

export const { reset, addFiles, updateFile, selectFile, rerunAnalysis, updateExperiment, deleteFile } = experimentFileSlice.actions;
export const selectExperimentFiles = (state) => state.experimentFiles;

export default experimentFileSlice.reducer;
