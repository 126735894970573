import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Switch, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { reset as resetExperimentFiles, selectExperimentFiles, selectFile, deleteFile } from "../store/reducers/experimentFile";
import vars from "../styles/variables";
import LOGO from "../assets/svg/logo-beta.svg";
import FOLDER from "../assets/svg/folder.svg";
import DOWNLOAD from "../assets/svg/download-grey.svg";
import BACK_ICON from "../assets/svg/chevron-left.svg";
import TEMPLATE from "../assets/svg/file-check.svg";
import FILE from "../assets/svg/file.svg";
import UPLOAD from "../assets/svg/upload.svg";
import DELETE_ICON from "../assets/svg/delete.svg";
// import Loading from '../assets/svg/loading.svg';
import DeleteLibrary from "./DeleteLibraryDialog";
import { ExperimentFile, ExperimentFileStatus } from "../types/ExperimentFile";
import LOAD from "../assets/img/loading.gif";
import LicenseUpload from "./LicenseUpload"; // Import the new LicenseUpload component
import FingerprintWidget from "./License/FingerprintWidget";

const { white, libraryHeadingColor, tabTextColor } = vars;

interface ISideBar {
  width: number;
  openAboutDialog: any;
  installBtnClick: any;
  openUploadDialog: any;
}

const SideBar: React.FC<ISideBar> = (props) => {
  const { width, openAboutDialog, installBtnClick, openUploadDialog } = props;
  const dispatch = useDispatch();
  const experimentFiles: ExperimentFile[] = useSelector(selectExperimentFiles);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const sidebarRoutes = [
    {
      // title: 'Sample List generator',
      title: "Main page",
      method: () => navigate("/"),
      icon: <TEMPLATE />,
    },
    {
      title: "SOPs library",
      method: () => navigate("/soplibrary"),
      icon: <FOLDER />,
    },
    {
      title: "Install desktop app",
      method: installBtnClick,
      icon: <DOWNLOAD />,
    },
    {
      title: "About",
      method: () => openAboutDialog(false),
      icon: <InfoOutlinedIcon />,
    },
  ];
  const classes = {
    root: {
      width: width,
      flexShrink: 0,
      background: white,
      flexDirection: "column",
      display: "flex",
    },

    upload: {
      padding: "1.5rem",
    },

    logo: {
      padding: "0 1.5rem",
      height: "5.375rem",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },

    version: {
      fontSize: "0.65rem",
      color: tabTextColor,
    },

    libraryHeading: {
      padding: "1.5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

      "& p": {
        fontWeight: 600,
        fontSize: "1rem",
        lineHeight: "1.5rem",
        color: libraryHeadingColor,
      },
    },

    library: {
      flexGrow: 1,
      padding: "0 1.5rem",
      overflow: "auto",

      "& .MuiListItem-root": {
        "&:not(:first-of-type)": {
          marginTop: "0.75rem",
        },
        "&:hover": {
          cursor: "pointer",
        },
      },

      "& .MuiListItemText-root": {
        "& .MuiListItemText-primary": {
          fontWeight: 500,
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        },
      },

      "& > p": {
        fontWeight: 500,
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        color: tabTextColor,
      },
    },

    footer: {
      padding: "1.5rem",
    },
  };
  const navigate = useNavigate();
  const location = useLocation()?.pathname;

  const deleteLibraryList = () => {
    dispatch(resetExperimentFiles());
    setDeleteDialog(false);
    navigate("/");
  };

  const switchHandler = (e, i, file) => {
    if (e.target.checked) {
      dispatch(selectFile(file));
      navigate("/analysis");
    }
  };

  const onDeleteFile = (file) => {
    dispatch(deleteFile(file));
  };

  return (
    <>
      <Box sx={classes.root}>
        <Box sx={classes.logo}>
          <LOGO onClick={() => navigate("/")} />
        </Box>
        <Divider />
        {location === "/soplibrary" ? (
          <>
            <Box flexGrow={1}>
              <Box p={3}>
                <Button onClick={() => navigate("/")} color="secondary" disableRipple>
                  <BACK_ICON />
                  Back to Dashboard
                </Button>
              </Box>
              <Divider />
            </Box>
            <Divider />
          </>
        ) : (
          <>
            <Box sx={classes.libraryHeading}>
              <Typography>Data Library</Typography>
              <IconButton onClick={() => setDeleteDialog(true)} disabled={experimentFiles.length === 0}>
                <DELETE_ICON />
              </IconButton>
            </Box>

            {experimentFiles.length > 0 ? (
              <Box sx={classes.library}>
                <List>
                  {experimentFiles.map((file, i) => {
                    return (
                      <ListItem
                        key={`library_${i}`}
                        onClick={() => navigate("/analysis")}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                      >
                        <ListItemIcon
                          sx={{
                            cursor: isHovered && file.analyzeStatus === ExperimentFileStatus.Analyzed ? "pointer" : "default",
                          }}
                        >
                          {isHovered && file.analyzeStatus === ExperimentFileStatus.Analyzed ? (
                            <DELETE_ICON onClick={() => onDeleteFile(file)} />
                          ) : file.analyzeStatus !== ExperimentFileStatus.Analyzed ? (
                            <img src={LOAD} width={20} alt="" />
                          ) : (
                            <FILE />
                          )}
                        </ListItemIcon>
                        <Tooltip title={file?.meta?.name}>
                          <ListItemText primary={file?.meta?.name} />
                        </Tooltip>
                        {file.analyzeStatus === ExperimentFileStatus.Analyzed && (
                          <Switch checked={file?.selected} edge="end" onChange={(e) => switchHandler(e, i, file)} />
                        )}
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            ) : (
              <Box sx={classes.library} display="flex" alignItems="center" justifyContent="center">
                <Typography>Library is empty.</Typography>
              </Box>
            )}

            <Box sx={classes.upload}>
              <Button fullWidth variant="contained" onClick={openUploadDialog}>
                <UPLOAD className="left-icon" />
                Upload a data file
              </Button>
            </Box>

            <Divider />
            <Box pt="1rem" pb="1rem">
              <FingerprintWidget />
            </Box>
            <Divider />
            <LicenseUpload />

            <Divider />
            <Box sx={classes.footer}>
              <List>
                {sidebarRoutes.map((route, i) => (
                  <ListItem key={`sidebaroptions${i}`}>
                    <ListItemButton onClick={route?.method}>
                      <ListItemIcon>{route?.icon}</ListItemIcon>
                      <ListItemText primary={route?.title} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </>
        )}
      </Box>

      <DeleteLibrary open={deleteDialog} handleClose={() => setDeleteDialog(false)} deleteLibraryList={deleteLibraryList} />
    </>
  );
};

export default SideBar;
