import React, { useState } from "react";
import { COCKTAIL_INPUTS } from "../../../../shared/constant";
import ChipInput from "../../Steps/ChipInput";
import Input from "../../Steps/Input";
import { Box, Typography } from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
import vars from "../../../../styles/variables";

const { inputDisableBorder, labelColor } = vars;
const KEYBOARD_KEY = {
  enter: "Enter",
};

const ACCEPTED_KEYS = [KEYBOARD_KEY.enter];

const classes = {
  root: {
    borderLeft: `0.25rem solid ${inputDisableBorder}`,
    paddingLeft: "1.5rem",
  },
  muiChipInput: {
    "& .MuiSvgIcon-root": {
      background: `url(../../../assets/svg/close.svg) no-repeat center`,
      fontSize: "2rem",
      "& path": {
        display: "none",
      },
    },
  },
  label: {
    color: labelColor,
  },
};

const PreIncubation = ({ label, cocktail, detailKey, heading, handleChange, tabValue, handleInputBlur, handleDurationInputChange, handleInputChange }) => {
  const [inputValue, setInputValue] = useState("");

  const handleBlur = (e) => {
    const value = e.target.value;
    if (value) {
      handleChange([value], COCKTAIL_INPUTS.drugName);
    }
    setInputValue("");
  };
  return (
    <>
      <Box mb={2} sx={classes.muiChipInput} flexGrow={1}>
        <Typography sx={classes.label} variant="body2">
          {label} {COCKTAIL_INPUTS.drugName}
        </Typography>
        <MuiChipsInput
          addOnWhichKey={ACCEPTED_KEYS}
          onChange={(val) => {
            const singleValue = val.length > 0 ? [val[val.length - 1]] : [];
            handleChange(singleValue, COCKTAIL_INPUTS.drugName);
            setInputValue("");
          }}
          value={cocktail[detailKey][COCKTAIL_INPUTS.drugName]}
          fullWidth
          hideClearAll
          inputProps={{
            value: inputValue,
            onBlur: handleBlur,
            onChange: (event) => {
              handleInputChange(event, COCKTAIL_INPUTS.drugName);
              setInputValue(event.target.value);
            },
          }}
        />
      </Box>
      <ChipInput
        mb={2}
        key={`${heading}_${tabValue}_${COCKTAIL_INPUTS.mediaVolumePerChip}`}
        val={cocktail[detailKey][COCKTAIL_INPUTS.mediaVolumePerChip]}
        index={tabValue}
        onChange={handleChange}
        detailKey={COCKTAIL_INPUTS.mediaVolumePerChip}
      />
      <ChipInput
        mb={2}
        label={`${label} ${COCKTAIL_INPUTS.concentration} (μM)`}
        key={`${heading}_${tabValue}_${COCKTAIL_INPUTS.concentration}`}
        val={cocktail[detailKey][COCKTAIL_INPUTS.concentration]}
        index={tabValue}
        onChange={handleChange}
        detailKey={COCKTAIL_INPUTS.concentration}
      />
      <ChipInput
        mb={2}
        key={`${heading}_${tabValue}_${COCKTAIL_INPUTS.treatmentStarts}`}
        val={cocktail[detailKey][COCKTAIL_INPUTS.treatmentStarts]}
        index={tabValue}
        onChange={handleChange}
        detailKey={COCKTAIL_INPUTS.treatmentStarts}
      />
      <ChipInput
        mb={2}
        key={`${heading}_${tabValue}_${COCKTAIL_INPUTS.incubationDuration}`}
        val={cocktail[detailKey][COCKTAIL_INPUTS.incubationDuration]}
        index={tabValue}
        onChange={(v) => handleDurationInputChange(v, COCKTAIL_INPUTS.incubationDuration)}
        onBlur={handleInputBlur}
        label={`${label} ${COCKTAIL_INPUTS.incubationDuration} (hrs)`}
        detailKey={COCKTAIL_INPUTS.incubationDuration}
      />
      <Input
        mb={2}
        multiline={true}
        rows={2}
        label={COCKTAIL_INPUTS.notes}
        value={cocktail[detailKey][COCKTAIL_INPUTS.notes]}
        onChange={(val) => handleChange(val, COCKTAIL_INPUTS.notes)}
      />
    </>
  );
};

export default PreIncubation;
