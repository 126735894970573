import { Box } from "@mui/system";
import React, { useState } from "react";
import vars from "../styles/variables";
import SideBar from "./Sidebar";
import AboutDialog from "./AboutModal";
import UploadDialog from "./FileUploader/UploadDialog";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addFiles as addExperimentFiles, selectFile } from "../store/reducers/experimentFile";

const { primaryColor, secondaryBg } = vars;

const ComponentWrapper = (props) => {
  const { children, handleClick, version, build } = props;
  const sideBarWidth = 264;
  const classes = {
    root: {
      borderTop: `0.125rem solid ${primaryColor}`,
      height: "100vh",
      display: "flex",
    },

    content: {
      height: "100%",
      width: `calc(100% - ${sideBarWidth}px)`,
      background: secondaryBg,
      overflow: "auto",
    },
  };
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [openAboutDialog, setOpenAboutDialog] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCloseUploadDialog = (uploads: any[]) => {
    if (uploads.length > 0) {
      dispatch(addExperimentFiles(uploads));
      dispatch(selectFile(uploads[0]));
      navigate("/analysis");
    }
    setOpenUploadDialog(false);
  };
  return (
    <>
      <Box sx={classes.root}>
        <SideBar
          width={sideBarWidth}
          openAboutDialog={() => setOpenAboutDialog(true)}
          installBtnClick={handleClick}
          openUploadDialog={() => setOpenUploadDialog(true)}
        />
        <UploadDialog open={openUploadDialog} handleClose={handleCloseUploadDialog} />
        <AboutDialog open={openAboutDialog} handleClose={() => setOpenAboutDialog(false)} version={version} build={build} />
        <Box sx={classes.content}>{children}</Box>
      </Box>
    </>
  );
};

export default ComponentWrapper;
