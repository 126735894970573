import React, { useState } from "react";
import Plot from "react-plotly.js";
import Box from "@mui/material/Box";
import { Card, CardContent, CardHeader, FormControl, Grid, IconButton, MenuItem, Select, Tooltip } from "@mui/material";
import DOWNLOAD from "../../assets/svg/download-grey.svg";
import vars from "../../styles/variables";

const { primaryFont } = vars;

const chartStyles = {
  height: "20rem",
  width: "100%",
};

const chartConfig = {
  displaylogo: false,
  displayModeBar: false,
  responsive: true,
};

const marginLayoutProps = {
  t: 0,
  l: 0,
  r: 0,
  b: 0,
};

const hoverLayoutProps = {
  bgcolor: "white",
  bordercolor: "rgba(0, 0, 0, 0.16)",
  font: {
    size: 12,
    color: "rgba(0, 0, 0, 0.6)",
  },
};

const layout = {
  hovermode: "x unified",

  font: {
    family: primaryFont,
  },

  margin: {
    ...marginLayoutProps,
  },

  barmode: "stack",

  hoverlabel: {
    ...hoverLayoutProps,
  },

  xaxis: {
    tickformat: ".2f",
  },

  yaxis: {
    tickformat: ".2f",
  },
};

export default function BarChart(props) {
  const classes = {
    card: {
      "& *": {
        fontFamily: `${primaryFont} !important`,
      },
      "& .MuiCardHeader-root": {
        "& .MuiCardHeader-title": {
          fontSize: "1rem",
          lineHeight: "1.5rem",
        },
      },

      "& .MuiCardContent-root": {
        position: "relative",

        "& .MuiButton-root": {
          position: "absolute",
          right: "0.75rem",
          bottom: "0.75rem",
        },
      },
    },
  };
  const chartId = `id-bc-${Math.random().toString(16).slice(2)}_chart`;

  return (
    <Grid item xs={12}>
      <Box sx={classes.card}>
        <Card variant="outlined">
          <CardHeader
            action={
              <Box display="flex" alignItems="center" gap={1}>
                <FormControl
                  sx={{
                    minWidth: 100,

                    "& .MuiSelect-select": {
                      paddingLeft: "0.5rem !important",
                    },
                  }}
                  fullWidth
                ></FormControl>
                <Tooltip placement="top" title="Download as image">
                  <IconButton onClick={(e) => props.downloadAsImage(chartId, "topRight")}>
                    <DOWNLOAD />
                  </IconButton>
                </Tooltip>
              </Box>
            }
            title={props?.plot.title}
          />

          <CardContent>
            <Plot data={props?.plot.data} layout={{ ...layout, ...props?.plot.layout }} config={chartConfig} style={chartStyles} divId={chartId} />
          </CardContent>
        </Card>
      </Box>
    </Grid>
  );
}
