import React from "react";
import { Chip } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

// computes if the row is rejected or not
// this is primitive, but the spec/requirement doesn't state
// what are the "marks" that represent a rejected data
// depending on the xlsx, sometimes it's the presence of a value vs. non-presence
// sometimes it's "x" vs. "a"...
const isMarkedRejected = (data, concentrationData) => {
  const markedAs = `${data}`.toLowerCase().trim();
  const concentrationMarkedAs = `${concentrationData}`.toLowerCase().trim();
  return ["x", "r"].includes(markedAs) || ["alq", "n.s."].includes(concentrationMarkedAs);
};

// With previous version, not all information from the xlsx are displayed
// as the information to display are now "derived" directly from the xlsx
// some "indexes" are rejected to emulate the previous version (compliant with the design)
//
// const rejectedHeaders = [4, 5, 6, 12]
const rowMapper = (row, rejectedHeaders = []) => row.filter((column, idx) => !rejectedHeaders.includes(idx));

const handleRowClick = (params) => {
  console.log(params);
};

export default function DrugTable(props) {
  const displayRows = props?.rows.map((row) => rowMapper(row, props.rejectedHeaders));
  const rows = displayRows.slice(1).map((row, idx) => {
    const o = { id: idx };
    row.forEach((c, idx) => (o[`c_${idx}`] = c));
    return o;
  });

  let concentrationColumn = null;
  const columns: GridColDef[] = displayRows.slice(0, 1)[0].map((c, idx) => {
    const headername = c.toLowerCase().trim();
    if (headername.includes("reject")) {
      return {
        field: `c_${idx}`,
        headerName: c.replaceAll('"', ""),
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<String>) => {
          const concentrationData = params.row[`c_${concentrationColumn}`];
          const isRejected = isMarkedRejected(params.value, concentrationData);
          return isRejected ? <Chip color="error" className="rounded" size="small" label="X" /> : "";
        },
      };
    } else {
      if (headername.includes("concentration") || headername.includes("data")) {
        concentrationColumn = idx;
      }
      return { field: `c_${idx}`, headerName: c, flex: 1 };
    }
  });

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10]}
      autoHeight
      hideFooter={props.hideFooter || false}
      onRowClick={handleRowClick}
    />
  );
}
