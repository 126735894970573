import FingerprintJS from "fingerprintjs2";

const base64UrlEncode = (arrayBuffer) => {
  const base64 = btoa(String.fromCharCode.apply(null, new Uint8Array(arrayBuffer)));
  return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
};

const generateValidKey = async (clientId) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(clientId);
  const hash = await crypto.subtle.digest("SHA-256", data);
  const base64Key = base64UrlEncode(hash);

  // Ensure the key is exactly 44 characters long to match the example provided
  return base64Key.padEnd(44, "=");
};

export const getClientId = async () => {
  const localStorageKey = "client_id_key";
  let key = localStorage.getItem(localStorageKey);

  if (key) {
    return key;
  }

  try {
    const components = await FingerprintJS.getPromise();

    // Filter out monitor specs related components
    const filteredComponents = components.filter(
      (component) =>
        component.key !== "screenResolution" &&
        component.key !== "availableScreenResolution" &&
        component.key !== "screenFrame" &&
        component.key !== "colorDepth" &&
        component.key !== "pixelRatio",
    );

    const values = filteredComponents.map((component) => component.value);
    const clientId = FingerprintJS.x64hash128(values.join(""), 31);

    // Generate a valid key from the clientId
    key = await generateValidKey(clientId);

    // Store the key in local storage
    localStorage.setItem(localStorageKey, key);

    return key;
  } catch (error) {
    console.error("Error generating fingerprint:", error);
  }

  return "";
};
