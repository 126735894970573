import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CloseIcon } from "../../shared/Icons";
import UploadIcon from "../../assets/svg/upload.svg";
import ExampleFiles from "./ExampleFiles";
import { getFileReader } from "./utils";
import FileUpload from "./FileUpload";

// const tabs = ["Data file", "NSB file"]
const tabs = ["Data file", "Example Files"];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const UploadDialog = (props) => {
  const { open, handleClose } = props;

  const [uploads, setUploads] = useState([]);
  const [exampleFiles, setExampleFiles] = useState([]);
  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: any) => {
    if (newValue > 1) {
      handleClose(uploads);
      setValue(0);
      setUploads([]);
    } else {
      setValue(newValue);
    }
  };

  const resetDialog = () => {
    setUploads([]);
    handleClose([]);
  };

  const handleExampleFileClick = (filename) => {
    const fileReader = getFileReader(setExampleFiles);
    import(`../../assets/uploadExampleFiles/${filename}`)
      .then((module) => fetch(module.default))
      .then((response) => response.arrayBuffer())
      .then((buffer) => {
        return new File([buffer], filename, { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      })
      .then((file) => fileReader(file));
  };

  useEffect(() => {
    if (exampleFiles.length > 0) {
      handleClose(exampleFiles);
      setExampleFiles([]);
    }
  }, [exampleFiles]);

  return (
    <Dialog open={open} onClose={resetDialog}>
      <Box className="bg-gray" display="flex" justifyContent="space-between" alignItems="flex-start" p={3} pb={1.5}>
        <DialogTitle>
          Upload your data
          <Typography>To get started or add new data to your dashboard, upload a standardized data file.</Typography>
        </DialogTitle>
        <IconButton sx={{ padding: 0 }} aria-label="close" onClick={resetDialog}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box className="bg-gray border-bottom" px={3}>
        <Tabs className="custom-arrow" value={value} onChange={handleChange}>
          {tabs.map((tab, index) => {
            return <Tab key={index} label={tab} {...a11yProps(index)} />;
          })}
        </Tabs>
      </Box>

      <DialogContent>
        <TabPanel value={value} index={0}>
          <Typography component="h3">Standardized data file</Typography>
          <FileUpload files={uploads} setFiles={setUploads} multiple={true} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ExampleFiles onFileClick={handleExampleFileClick} />
        </TabPanel>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={(e) => handleChange(e, 2)} disabled={uploads?.length === 0}>
          <UploadIcon className="left-icon" />
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadDialog;
