import ErrorDialog from "./ErrorDialog";
import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const ImportFileErrorDialog = ({ error, open, handleErrorDialogClose }) => {
  return (
    <ErrorDialog open={open} onClose={handleErrorDialogClose}>
      <Box>
        {Object.keys(error).map((err, i) => (
          <Stack key={i} direction="row" alignItems="baseline" textAlign="left">
            <Typography variant="body2" fontWeight={700}>
              {err}:{" "}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                textAlign: "left !important",
              }}
            >
              {" "}
              {error[err]}
            </Typography>
          </Stack>
        ))}
      </Box>
    </ErrorDialog>
  );
};

export default ImportFileErrorDialog;
