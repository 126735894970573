import React from "react";
import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
import { CHIP_INPUTS, COCKTAIL_INPUTS } from "../../../../shared/constant";
import ChipInput from "../../Steps/ChipInput";
import SelectInput from "../../Steps/SelectInput";

const ChipWithTissue = ({
  cocktail,
  detailKey,
  heading,
  handleChange,
  handleInputChange,
  handleDelete,
  defaultLocations,
  inputValues,
  tabValue,
  chips,
  handleChangeIntracellular,
  handleDeleteIntracellular,
  ACCEPTED_KEYS,
  classes,
}) => (
  <>
    <Box mb={2} sx={classes.muiChipInput} flexGrow={1}>
      <Typography sx={classes.label} variant="body2">
        Sample Collection Times (hrs)
      </Typography>
      <MuiChipsInput
        addOnWhichKey={ACCEPTED_KEYS}
        value={cocktail[detailKey][COCKTAIL_INPUTS.timepoints]}
        onChange={(val) => handleChange(val, COCKTAIL_INPUTS.timepoints)}
        fullWidth
        hideClearAll
        inputProps={{
          value: inputValues[COCKTAIL_INPUTS.timepoints] || "",
          onBlur: (e) => {
            const value = e.target.value;
            if (value) {
              const currentValues = cocktail[detailKey][COCKTAIL_INPUTS.timepoints] || [];
              handleChange([...currentValues, value], COCKTAIL_INPUTS.timepoints);
            }
          },
          onChange: (event) => handleInputChange(event, COCKTAIL_INPUTS.timepoints),
        }}
      />
    </Box>
    <ChipInput
      mb={2}
      key={`${heading}_${tabValue}_${COCKTAIL_INPUTS.sampleVolume}`}
      val={cocktail[detailKey][COCKTAIL_INPUTS.sampleVolume]}
      index={tabValue}
      onChange={handleChange}
      detailKey={COCKTAIL_INPUTS.sampleVolume}
      label={`${COCKTAIL_INPUTS.sampleVolume} (ul)`}
    />
    <SelectInput
      inputLabel={`${COCKTAIL_INPUTS.locations}`}
      placeholder="Select one or multiple sample locations"
      inputChange={handleChange}
      value={cocktail[detailKey][COCKTAIL_INPUTS.locations]}
      chipIndex={tabValue}
      defaultConditions={defaultLocations}
      detailKey={COCKTAIL_INPUTS.locations}
      handleDelete={(chipIndex) => handleDelete(chipIndex, cocktail, detailKey, COCKTAIL_INPUTS.locations)}
    />
    <SelectInput
      inputLabel={COCKTAIL_INPUTS.linkedChips}
      placeholder="Select one or multiple linked chips"
      inputChange={handleChange}
      value={cocktail[detailKey][COCKTAIL_INPUTS.linkedChips]}
      chipIndex={tabValue}
      multiple={true}
      defaultConditions={chips.map((c) => c[CHIP_INPUTS.id])}
      detailKey={COCKTAIL_INPUTS.linkedChips}
      handleDelete={(chipIndex) => handleDelete(chipIndex, cocktail, detailKey, COCKTAIL_INPUTS.linkedChips)}
    />
    <ChipInput
      mb={2}
      key={`${heading}_${tabValue}_${COCKTAIL_INPUTS.technicalReplicates}`}
      val={cocktail[detailKey][COCKTAIL_INPUTS.technicalReplicates]}
      index={tabValue}
      onChange={handleChange}
      detailKey={COCKTAIL_INPUTS.technicalReplicates}
    />
    <FormControlLabel
      control={<Switch />}
      label="Add Intracellular Condition properties"
      onChange={(e) => handleChangeIntracellular(e?.target?.checked, "selected")}
      checked={cocktail[detailKey][COCKTAIL_INPUTS.intracellular]?.selected}
      sx={{
        my: 4,
      }}
    />
    {cocktail[detailKey][COCKTAIL_INPUTS.intracellular]?.selected && (
      <>
        <ChipInput
          mb={2}
          key={`${heading}_${tabValue}_${COCKTAIL_INPUTS.sampleVolume}_0`}
          val={cocktail[detailKey][COCKTAIL_INPUTS.intracellular][COCKTAIL_INPUTS.sampleVolume]}
          index={tabValue}
          onChange={handleChangeIntracellular}
          detailKey={COCKTAIL_INPUTS.sampleVolume}
        />
        <SelectInput
          inputLabel={COCKTAIL_INPUTS.locations}
          placeholder="Select one or multiple sample locations"
          inputChange={handleChangeIntracellular}
          value={cocktail[detailKey][COCKTAIL_INPUTS.intracellular][COCKTAIL_INPUTS.locations]}
          chipIndex={tabValue}
          defaultConditions={defaultLocations}
          detailKey={COCKTAIL_INPUTS.locations}
          handleDelete={(chipIndex) => handleDeleteIntracellular(chipIndex, cocktail, detailKey, "locations")}
        />
        <ChipInput
          mb={2}
          key={`${heading}_${tabValue}_${COCKTAIL_INPUTS.technicalReplicates}_1`}
          val={cocktail[detailKey][COCKTAIL_INPUTS.intracellular][COCKTAIL_INPUTS.technicalReplicates]}
          index={tabValue}
          onChange={handleChangeIntracellular}
          detailKey={COCKTAIL_INPUTS.technicalReplicates}
        />
      </>
    )}
  </>
);

export default ChipWithTissue;
