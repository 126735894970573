import React from "react";
import { Dialog, DialogContent, Typography } from "@mui/material";
import CircularProgress, { circularProgressClasses, CircularProgressProps } from "@mui/material/CircularProgress";
import vars from "../../styles/variables";
import { Box } from "@mui/system";

const { dialogBorderColor, progressBorderColor, labelColor } = vars;

const Loader = (props: CircularProgressProps) => {
  return (
    <Box display="inline-flex" sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: dialogBorderColor,
        }}
        size={32}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: progressBorderColor,
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={32}
        thickness={4}
        {...props}
      />
    </Box>
  );
};

const DownloadLoaderDialog = ({ open, handleClose }) => {
  const classes = {
    root: {
      textAlign: "center",
      "& .MuiTypography-root": {
        fontWeight: 500,
        fontSize: "0.875rem",
        margin: "1.125rem 0 1rem",
        lineHeight: "1.25rem",
        color: labelColor,
      },
    },
  };

  return (
    <Dialog fullWidth maxWidth="xs" sx={classes.root} open={open} onClose={handleClose}>
      <DialogContent className="height-auto">
        <Loader />
        <Typography>Processing the data...</Typography>
        {/* <Button disableRipple onClick={handleClose}>Cancel</Button> */}
      </DialogContent>
    </Dialog>
  );
};

export default DownloadLoaderDialog;
