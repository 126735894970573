import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Box } from "@mui/material";

export default function SubHeaderDrugTable({ headers, rows }) {
  const defaultColDef = {
    resizable: true,
    initialWidth: 70,
    sortable: false,
  };
  return (
    <Box className="ag-theme-alpine" style={{ height: 400, width: "100%" }}>
      <AgGridReact
        suppressRowTransform
        columnDefs={headers}
        rowHeight={52}
        rowData={rows}
        suppressAutoSize
        defaultColDef={defaultColDef}
        multiSortKey={"ctrl"}
        getRowClass={(params) => {
          if (params.node.rowIndex % 2 === 1) {
            return "bottow-row";
          }
        }}
      />
    </Box>
  );
}
