import { utils } from "xlsx";

class XlsxSheet {
  static get CustomCellClass() {
    return XlsxCell;
  }

  constructor(sheet, name, workbook) {
    this.workbook = workbook;
    this.sheet = sheet;
    this.name = name;
    this._cache = {};

    return new Proxy(this, {
      get(instance, key) {
        if (key in instance) {
          return instance[key];
        }
        return instance._basicGetOrWrap(key);
      },
    });
  }

  sJson() {
    return utils.sheet_to_json(this.sheet, {
      defval: null,
    });
  }

  asCSV() {
    return utils.sheet_to_csv(this.sheet, {
      defval: null,
      FS: ";",
    });
  }

  // this will only works if there is no ";" used in a cell
  asArray() {
    const str = this.asCSV();
    // We are sure it's a \n, default value of xlsx lib
    return str
      .split("\n")
      .filter((x) => !x.match(/^;+$/))
      .map((x) => x.split(";"));
  }

  _basicGetOrWrap(key) {
    if (key in this._cache) {
      return this._cache[key];
    }
    const wrapper = new this.constructor.CustomCellClass(this, key, this.sheet[key]);
    this._cache[key] = wrapper;
    return wrapper;
  }

  getCell(cellPosition) {
    const position = utils.encode_cell(cellPosition);
    return this._basicGetOrWrap(position);
  }

  find(contents) {
    const result = {};
    for (const i in this.sheet) {
      const cell = this.sheet[i];
      const searchList = [...contents];
      for (const content of searchList) {
        if (cell.v?.toString()?.startsWith(content)) {
          result[content] = this._basicGetOrWrap(i);
          contents.splice(contents.indexOf(content), 1);
          break;
        }
      }
      if (contents.length === 0) {
        break;
      }
    }
    return result;
  }
}

class XlsxCell {
  constructor(owningSheet, position, cellobj) {
    this.cell = cellobj;
    this.position = position;
    this.owning_sheet = owningSheet;
    const { c, r } = utils.decode_cell(this.position);
    this.row = r;
    this.col = c;
  }

  left() {
    const { c, r } = utils.decode_cell(this.position);
    return this.owning_sheet.getCell({ r, c: c - 1 });
  }

  right() {
    const { c, r } = utils.decode_cell(this.position);
    return this.owning_sheet.getCell({ r, c: c + 1 });
  }

  up() {
    const { c, r } = utils.decode_cell(this.position);
    return this.owning_sheet.getCell({ r: r - 1, c });
  }

  down() {
    const { c, r } = utils.decode_cell(this.position);
    return this.owning_sheet.getCell({ r: r + 1, c });
  }

  get t() {
    try {
      return this.cell.t;
    } catch (error) {
      return undefined;
    }
  }

  get v() {
    try {
      return this.cell.v;
    } catch (error) {
      return undefined;
    }
  }

  get value() {
    return utils.format_cell(this.cell);
  }
}

const groupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

export { XlsxSheet, groupBy };
