/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
let pyodideWorker;
let interruptBuffer;

const startPythonWebWorker = () => {
  if (pyodideWorker === undefined) {
    pyodideWorker = new Worker(new URL("./pythonWebWorker.js", import.meta.url));
  }
  interruptBuffer = new Uint8Array(new SharedArrayBuffer(1));
  pyodideWorker.postMessage({
    type: "setInterruptBuffer",
    interruptBuffer,
  });

  pyodideWorker.onmessage = (event) => {
    // const { msgType, id, msg, ...data } = Object.fromEntries(event.data);
    const { msgType, id, msg, ...data } = event.data;
    let onSuccess;
    let onError;
    switch (msgType) {
      case "cb":
        onSuccess = callbacks[id];
        if (onSuccess) {
          delete callbacks[id];
          onSuccess(data);
        }
        break;
      case "log":
        console.log(msg);
        break;
      case "err":
      case "error":
        onError = callbacks[id];
        delete callbacks[id];
        if (onError) {
          const { msg, type } = data.results;
          if (type === "VM_INTERRUPTION") {
            console.log("SIGINT received, computation is stopped");
            return;
          }
          const error = new Error(msg);
          onError(error);
        } else {
          console.error(`Error in worker: ${data.results}`);
        }
        break;
      default:
        console.log(`default: ${msgType} -- ${msg}`);
    }
  };
};

const callbacks = {};

const resetInterruptBuffer = () => {
  interruptBuffer[0] = 0;
};

const setSIGINT = () => {
  interruptBuffer[0] = 2;
};

const asyncRun = (() => {
  let id = 0; // identify a Promise
  return (script, context) => {
    // the id could be generated more carefully
    id = (id + 1) % Number.MAX_SAFE_INTEGER;
    return new Promise((resolve, reject) => {
      callbacks[id] = (result) => {
        if (result instanceof Error) {
          reject(result);
        } else {
          resolve(result);
        }
      };
      resetInterruptBuffer();
      pyodideWorker.postMessage({
        ...context,
        python: script,
        type: "script",
        id,
      });
    });
  };
})();

const asyncRunStoreFile = (() => {
  let id = 0; // identify a Promise
  return (file, fileId) => {
    id = (id + 1) % Number.MAX_SAFE_INTEGER;
    return new Promise((resolve, reject) => {
      callbacks[id] = (result) => {
        if (result instanceof Error) {
          reject(result);
        } else {
          resolve(result);
        }
      };
      resetInterruptBuffer();
      pyodideWorker.postMessage({
        type: "storeFile",
        file,
        fileId,
        id,
      });
    });
  };
})();

// We build a proxy that catches all calls towards itself
// and transform calls into a message towards the python web worker
// We build a proxy that catches all calls towards itself
// and transform calls into a message towards the python web worker
class AsyncPythonModule {
  constructor(modulename, id) {
    this.name = modulename;
    this.id = id;
    this.count = 0;
    return new Proxy(this, {
      get(instance, key) {
        if (key in instance) {
          return Reflect.get(instance, key);
        }
        // if the key doesn't exist, we build a proxy for the method
        return new Proxy(() => {}, {
          apply: (_, thisArg, argumentsList) => {
            instance.count = (instance.count + 1) % Number.MAX_SAFE_INTEGER;
            const callid = `module_${instance.id}_${instance.count}`;
            return new Promise((resolve, reject) => {
              callbacks[callid] = (result) => {
                if (result instanceof Error) {
                  reject(result);
                } else {
                  resolve(result);
                }
              };
              resetInterruptBuffer();
              pyodideWorker.postMessage({
                ...argumentsList,
                python: {
                  modulename: instance.name,
                  funcname: key,
                },
                type: "module",
                id: callid,
              });
            });
          },
        });
      },
    });
  }
}

const importModule = (() => {
  let id = 0;
  return (modulename) => {
    id = (id + 1) % Number.MAX_SAFE_INTEGER;
    return new AsyncPythonModule(modulename, id);
  };
})();

const interruptPythonVM = () => {
  setSIGINT();
};

// We need to pre-start the worker here
startPythonWebWorker();

export { asyncRunStoreFile, asyncRun, importModule, interruptPythonVM, startPythonWebWorker };
