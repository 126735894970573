// licensingSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const initialState = {
  ClientName: "",
  Token: "",
  ExpirationDate: "",
  Modules: {}, // Use an object to store Modules
};

const licensingSlice = createSlice({
  name: "licensing",
  initialState,
  reducers: {
    reset: () => initialState,
    setLicensingInfo: (state, action) => {
      const payload = action.payload;

      state.ClientName = payload.ClientName;
      state.Token = payload.Token;
      state.ExpirationDate = payload.ExpirationDate;
      state.Modules = payload.Modules;
    },
  },
});

export const { reset, setLicensingInfo } = licensingSlice.actions;

const selectLicensingState = (state) => state.licensing;

export const selectLicensing = createSelector([selectLicensingState], (licensingState) => {
  return {
    ...licensingState,
    Modules: { ...licensingState.Modules },
  };
});

export default licensingSlice.reducer;
