import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./styles/style.less";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./styles/theme";
import Dashboard from "./components/Dashboard";
import initInstallListeners from "./services/InstallService";
import SopLibrary from "./components/SopLibrary";
import ComponentWrapper from "./components/ComponentWrapper";
import { analyseModels } from "./utils/javelinanalyser";
import { parseModelsFromXLSX } from "./utils/javelinimporter";
import { ExperimentFileStatus } from "./types/ExperimentFile";
import { updateFile, selectExperimentFiles } from "./store/reducers/experimentFile";
import AnalysisConditional from "./components/AnalysisConditional";
import { parseTraceback } from "./utils/errorMessageFormatter";
import ImportFileErrorDialog from "./components/ImportFileErrorDialog";

const startAnalysis = async (dispatch, experimentFiles, setError) => {
  const filesToAnalyze = experimentFiles.filter((file) => file.analyzeStatus === ExperimentFileStatus.Pending);
  for (const file of filesToAnalyze) {
    try {
      dispatch(updateFile({ ...file, analyzeStatus: ExperimentFileStatus.Running }));

      const models = await parseModelsFromXLSX(file.content, file.meta.name, file.includeRejected);
      dispatch(
        updateFile({
          ...file,
          data: models.importedJson,
          experimentType: models.importedJson?.model.info.experiment_type,
          analyzeStatus: ExperimentFileStatus.XlsxImported,
        }),
      );

      const analysisModel = await analyseModels(models.importedJson);
      dispatch(
        updateFile({
          ...file,
          data: models.importedJson,
          experimentType: models.importedJson.model.info.experiment_type,
          results: analysisModel,
          analyzeStatus: ExperimentFileStatus.Analyzed,
        }),
      );
    } catch (err) {
      dispatch(updateFile({ ...file, analyzeStatus: ExperimentFileStatus.Error }));
      setError(parseTraceback(err.message));
    }
  }
};

const Main = (props) => {
  const dispatch = useDispatch();
  const experimentFiles = useSelector(selectExperimentFiles);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [error, setError] = useState(null);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const handleErrorDialogClose = () => {
    setIsErrorDialogOpen(false);
    setError(null);
  };

  const installApp = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      await deferredPrompt.userChoice;
      setDeferredPrompt(null);
    }
  };

  useEffect(() => {
    initInstallListeners(setDeferredPrompt);
  }, []);

  useEffect(() => {
    const analyzeFiles = async () => {
      try {
        if (experimentFiles.length > 0) {
          await startAnalysis(dispatch, experimentFiles, setError);
          if (error) {
            setIsErrorDialogOpen(true);
          }
        }
      } catch (error) {
        setError(error.message);
        setIsErrorDialogOpen(true);
      }
    };

    analyzeFiles();
  }, [dispatch, experimentFiles, error]);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ComponentWrapper handleClick={installApp} {...props}>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/soplibrary" element={<SopLibrary />} />
            <Route path="/analysis" element={<AnalysisConditional />} />
          </Routes>
          {isErrorDialogOpen && <ImportFileErrorDialog open={isErrorDialogOpen} error={error} handleErrorDialogClose={handleErrorDialogClose} />}
        </ComponentWrapper>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default Main;
