import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import ExperimentDetail from "./Steps/ExperimentDetail";
import ExperimentSetup from "./Steps/ExperimentSetup";
import ChipDetails from "./Steps/ChipDetails";
import LoadingDialog from "./Steps/LoadingDialog";
import CompletionDialog from "./Steps/CompletionDialog";
import TabWrapper from "./TabWrapper";
import vars from "../../styles/variables";
import { CHIP_INPUTS, COCKTAIL_INPUTS, EXPERIMENT_INPUTS, LOCAL_STORAGE_EXPERIMENTS, STUDY_TYPES, isPlusType } from "../../shared/constant";
import { extractXLSXWorkbook } from "../../utils/javelinimporter";
import ImportDialog from "../FileUploader/ImportDialog";
import localStorageService from "../../services/localStorageService";
import FieldsValidationDialog from "./Steps/FieldsValidationDialog";

import { checkFields } from "../../helpers/fieldsValidation";
import { ActiveExperimentType } from "../../store/reducers/experimentFile";
import { populateDDIFromTemplate } from "./importers/DDIImporter.js";
import { populateHCFromTemplate } from "./importers/HCImporter.js";

import {
  experimentDefaults,
  inducerExperimentDefaults,
  inhibitionExperimentDefaults,
  cocktailDefaults,
  inhibitionCocktailDefaults,
  preIncubationDefaults,
} from "./importers/defaultValues.js";

import { hasPlusType } from "../../shared/constant";

const tabHeaders = {
  [STUDY_TYPES.Clearence]: ["Experiment Setup", "Chip Details", "Drug Solution", "Chip with Tissue", "Chip only"],
  [STUDY_TYPES.Inducer]: ["Experiment Setup", "Chip Details", "Induction Pre-Incubation", "Induction Drug & Victim Drug", "Sampling Time", "Chip only"],
  [STUDY_TYPES.Inhibition]: ["Experiment Setup", "Chip Details", "Inhibition Pre-Incubation", "Inhibition Drug & Victim Drug", "Sampling Time", "Chip only"],
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
};

const cloneExperiment = (experiment) => {
  return JSON.parse(JSON.stringify(experiment));
};

const getLocalStorageExperiments = () => {
  const stringversion = JSON.stringify(localStorageService.get(LOCAL_STORAGE_EXPERIMENTS));
  return JSON.parse(stringversion.replace(/"Reoxygenation Chamber"/i, '"Oxygenation Chamber"'));
};

const defaultExperiments = {
  [STUDY_TYPES.Clearence]: experimentDefaults,
  [STUDY_TYPES.Inducer]: inducerExperimentDefaults,
  [STUDY_TYPES.Inhibition]: inhibitionExperimentDefaults,
};

const resetExperimentsInLocalStorage = () => {
  const basicExps = {
    [EXPERIMENT_INPUTS.studyType]: STUDY_TYPES.Clearence,
  };
  for (const [key, experiment] of Object.entries(defaultExperiments)) {
    basicExps[key] = cloneExperiment(experiment);
  }
  localStorageService.set(LOCAL_STORAGE_EXPERIMENTS, basicExps);
  return basicExps;
};

const getInitialExperiments = () => {
  const lsExperiments = getLocalStorageExperiments();
  if (lsExperiments) {
    return lsExperiments;
  }
  return resetExperimentsInLocalStorage();
};

const getExperimentFromLocalStorage = () => {
  const initialExperiments = getInitialExperiments();
  const initialStudyType = initialExperiments[EXPERIMENT_INPUTS.studyType];
  let experiment = initialExperiments[initialStudyType];
  if (!experiment || !(initialStudyType in tabHeaders)) {
    console.log(`We cannot find an existing experiment for ${initialStudyType}, we reset the previously stored information for the studies`);
    resetExperimentsInLocalStorage();
    experiment = initialExperiments[initialStudyType];
  }

  return [initialStudyType, experiment];
};

export default function CreateExperimentDialog(props) {
  const { open, handleClose, setOpen } = props;
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [excelGeneration, setExcelGeneration] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [tabValueCocktail, setTabValueCocktail] = useState(0);
  const [inducerTabValue, setInducerTabValue] = useState(0);
  const [elementFocus, setElementFocus] = useState(false);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);

  const [initialStudyType, expe] = getExperimentFromLocalStorage();
  // const initialExperiments = getInitialExperiments();
  // const initialStudyType = initialExperiments[EXPERIMENT_INPUTS.studyType];
  // const [experiment, setExperiment] = useState(initialExperiments[initialStudyType]);
  const [experiment, setExperiment] = useState(expe);

  const [tabs, setTabs] = useState(tabHeaders[initialStudyType]);
  const [studyType, setStudyType] = useState(initialStudyType);
  const [isChipOnly, setIsChipOnly] = useState(experiment[EXPERIMENT_INPUTS.isChipOnly]);
  const [showFieldsValidationDialog, setShowFieldsValidationDialog] = useState(false);
  const [emptyFields, setEmptyFields] = useState([]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleTabChangeCocktail = (event: React.SyntheticEvent, newValue: number) => {
    setTabValueCocktail(newValue);
  };

  const handleTabChangeInducer = (event: React.SyntheticEvent, newValue: number) => {
    setInducerTabValue(newValue);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const maxValue = tabs.length - (isChipOnly ? 0 : 1);
    if (newValue === maxValue) {
      const missingFields = checkFields(experiment, studyType);
      setValue(0);
      if (missingFields.length > 0) {
        setEmptyFields(missingFields);
        setShowFieldsValidationDialog(true);
        setExcelGeneration(false);
      } else {
        setEmptyFields([]);
        setShowFieldsValidationDialog(false);
        setExcelGeneration(true);
      }
    } else {
      setValue(newValue);
    }

    setTabValueCocktail(0);
    setTabValue(0);
    setInducerTabValue(0);
    console.log("tabWrapper");
  };

  const handleCloseDialog = () => {
    setValue(0);
    setEmptyFields([]);
    setShowFieldsValidationDialog(false);
    setExcelGeneration(false);
    handleClose();
  };

  const switchStudyType = (type) => {
    setStudyType(type);
    setTabs(tabHeaders[type]);
  };

  const handleImportFromFile = (uploads: any[]) => {
    if (uploads.length > 0) {
      const file = uploads[0];
      try {
        const template = extractXLSXWorkbook(file.content, file.meta.name).extractSheets();
        const studyType = template.experimentType;
        const subStudyType = template.expinfo.infos.experiment_subtype;
        switchStudyType(subStudyType);
        setInducerTabValue(0);
        const { experiment, isChipOnly } =
          studyType === ActiveExperimentType.DDI ? populateDDIFromTemplate(template, subStudyType) : populateHCFromTemplate(template);
        if (experiment) {
          setIsChipOnly(isChipOnly);
          setExperiment(experiment);
        }
        setTabValue(0);
        setTabValueCocktail(0);
      } catch (e) {
        console.error(e);
        setOpenUploadDialog(false);
        return;
      }
    }
    setOpenUploadDialog(false);
  };

  const addChip = () => {
    // add new chip with default values as previous chip
    // @ts-ignore
    const templateChip = {
      ...experiment[EXPERIMENT_INPUTS.chips][experiment[EXPERIMENT_INPUTS.chips].length - 1],
      [CHIP_INPUTS.id]: "",
    };

    // @ts-ignore
    experiment[EXPERIMENT_INPUTS.chips].push(templateChip);
    setExperiment({
      ...experiment,
    });
    setTabValue(experiment[EXPERIMENT_INPUTS.chips].length - 1);
  };

  const removeChip = (indexToRemove) => {
    // @ts-ignore
    const newChips = experiment[EXPERIMENT_INPUTS.chips].filter((element, index) => index !== indexToRemove);
    setExperiment({
      ...experiment,
      [EXPERIMENT_INPUTS.chips]: [...newChips],
    });
    if (experiment[EXPERIMENT_INPUTS.chips].length - 1 === tabValue) {
      setTabValue(tabValue - 1);
    }
  };

  const chipValueChange = (value, key, index) => {
    const updatedChips = [...experiment[EXPERIMENT_INPUTS.chips]];
    // @ts-ignore
    updatedChips[index] = { ...updatedChips[index], [key]: value };
    // @ts-ignore
    setExperiment({
      ...experiment,
      [EXPERIMENT_INPUTS.chips]: [...updatedChips],
    });
  };
  const addCocktail = () => {
    // add new cocktail with default values
    // @ts-ignore
    if (studyType === STUDY_TYPES.Clearence) {
      experiment.cocktails.push({ ...cocktailDefaults });
    } else {
      experiment.cocktails.push({ ...inhibitionCocktailDefaults });
    }
    setExperiment({
      ...experiment,
    });
    setTabValueCocktail(experiment[EXPERIMENT_INPUTS.cocktails].length - 1);
  };

  const addInducer = () => {
    experiment.inducerdrugs.push({
      [COCKTAIL_INPUTS.perpetrator]: { ...preIncubationDefaults },
    });
    experiment[EXPERIMENT_INPUTS.cocktails].push(studyType === STUDY_TYPES.Clearence ? { ...cocktailDefaults } : { ...inhibitionCocktailDefaults });

    setInducerTabValue(experiment[EXPERIMENT_INPUTS.inducerdrugs].length - 1);
    setExperiment({
      ...experiment,
    });
  };

  const removeInducer = (indexToRemove) => {
    const inducerName = experiment[EXPERIMENT_INPUTS.inducerdrugs][indexToRemove][COCKTAIL_INPUTS.perpetrator][COCKTAIL_INPUTS.drugName];
    experiment[EXPERIMENT_INPUTS.cocktails]
      .filter((c) => c[COCKTAIL_INPUTS.victimDrug][COCKTAIL_INPUTS.preIncubationDrugName].includes(inducerName))
      .forEach((c) => (c[COCKTAIL_INPUTS.victimDrug][COCKTAIL_INPUTS.preIncubationDrugName] = []));

    const newlist = experiment[EXPERIMENT_INPUTS.inducerdrugs].filter((_, index) => index !== indexToRemove);
    setExperiment({
      ...experiment,
      [EXPERIMENT_INPUTS.inducerdrugs]: newlist,
    });
    if (experiment[EXPERIMENT_INPUTS.inducerdrugs].length - 1 === inducerTabValue) {
      setInducerTabValue(inducerTabValue - 1);
    }
  };

  const removeCocktail = (indexToRemove) => {
    // @ts-ignore
    const newCocktails = experiment[EXPERIMENT_INPUTS.cocktails].filter((_, index) => index !== indexToRemove);
    setExperiment({
      ...experiment,
      [EXPERIMENT_INPUTS.cocktails]: [...newCocktails],
    });
    if (experiment[EXPERIMENT_INPUTS.cocktails].length - 1 === tabValueCocktail) {
      setTabValueCocktail(tabValueCocktail - 1);
    }
  };

  const cocktailValueChange = (cocktail, index = null) => {
    const updatedCocktails = [...experiment[EXPERIMENT_INPUTS.cocktails]];
    updatedCocktails[index || tabValueCocktail] = { ...cocktail };
    // @ts-ignore
    setExperiment({
      ...experiment,
      [EXPERIMENT_INPUTS.cocktails]: [...updatedCocktails],
    });
  };

  const inducerValueChange = (inducer, index = null) => {
    const updatedInducers = [...experiment[EXPERIMENT_INPUTS.inducerdrugs]];
    updatedInducers[index || inducerTabValue] = { ...inducer };
    // @ts-ignore
    setExperiment({
      ...experiment,
      [EXPERIMENT_INPUTS.inducerdrugs]: [...updatedInducers],
    });
  };

  const experimentIdValueChange = (experimentID) => {
    // @ts-ignore
    setExperiment({
      ...experiment,
      [EXPERIMENT_INPUTS.id]: experimentID,
    });
  };

  const experimentStudyTypeValueChange = (type) => {
    const effectiveType = type || STUDY_TYPES.Clearence;

    // @ts-ignore
    setExperiment({
      ...experiment,
      [EXPERIMENT_INPUTS.studyType]: effectiveType,
    });
    setStudyType(effectiveType);
    setTabValue(0);
    setTabValueCocktail(0);
    setExperiment(cloneExperiment(getLocalStorageExperiments()[effectiveType]));
    setTabs(tabHeaders[effectiveType]);
  };

  const experimentIsChipOnlyValueChange = (value) => {
    // @ts-ignore
    setExperiment({
      ...experiment,
      [EXPERIMENT_INPUTS.isChipOnly]: value,
    });
    setIsChipOnly(value);
  };
  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const resetExperiment = () => {
    const defaultExperiment = cloneExperiment(defaultExperiments[studyType]);
    setExperiment(defaultExperiment);
    setTabValue(0);
    setTabValueCocktail(0);
    setInducerTabValue(0);
  };

  useEffect(() => {
    localStorageService.set(LOCAL_STORAGE_EXPERIMENTS, {
      ...localStorageService.get(LOCAL_STORAGE_EXPERIMENTS),
      [EXPERIMENT_INPUTS.studyType]: studyType,
      [studyType]: experiment,
    });
  }, [experiment]);

  const classes = {
    shadow: {
      boxShadow: "0 12px 16px -4px rgba(16, 24, 40, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03)",
    },
  };

  const renderTabContent = (tab) => {
    switch (tab) {
      case "Experiment Setup":
        return (
          <TabWrapper
            handleChange={handleChange}
            value={value}
            heading={`${value + 1}. ${tabs[0]}`}
            setElementFocus={setElementFocus}
            onImportFromFileClick={() => setOpenUploadDialog(true)}
            onResetDefaults={resetExperiment}
            studyType={studyType}
            isChipOnly={isChipOnly}
          >
            <ExperimentSetup
              experimentId={experiment[EXPERIMENT_INPUTS.id]}
              setExperimentId={experimentIdValueChange}
              setStudyType={experimentStudyTypeValueChange}
              studyType={studyType}
              isChipOnly={isChipOnly}
              setIsChipOnly={experimentIsChipOnlyValueChange}
              setTabValue={setTabValue} // Pass the function to update tabValue
            />
            <ImportDialog open={openUploadDialog} handleClose={handleImportFromFile} />
          </TabWrapper>
        );
      case "Chip Details":
        return (
          <TabWrapper
            tabValue={tabValue}
            handleTabChange={handleTabChange}
            onAdd={() => addChip()}
            onRemove={removeChip}
            arr={experiment[EXPERIMENT_INPUTS.chips]}
            handleChange={handleChange}
            value={value}
            heading={`${value + 1}. ${tabs[1]}`}
            setElementFocus={setElementFocus}
            studyType={studyType}
            isChipOnly={isChipOnly}
            addButtonLabel="Add a chip"
          >
            <ChipDetails
              tabValue={tabValue}
              studyType={studyType}
              chip={experiment[EXPERIMENT_INPUTS.chips][tabValue]}
              onChange={(val, field, tabValue) => {
                chipValueChange(val, field, tabValue);
              }}
              elementFocus={elementFocus}
              onAdd={() => addChip(tabValue)}
              onRemove={(chipId) => removeChip(chipId)}
              isPlusProcess={hasPlusType(experiment[EXPERIMENT_INPUTS.chips])}
            />
          </TabWrapper>
        );
      case "Drug Solution":
        return (
          <TabWrapper
            tabValue={tabValueCocktail}
            handleTabChange={handleTabChangeCocktail}
            onAdd={addCocktail}
            onRemove={removeCocktail}
            arr={experiment[EXPERIMENT_INPUTS.cocktails]}
            handleChange={handleChange}
            value={value}
            heading={`${value + 1}. ${tabs[2]}`}
            cocktailValueChange={cocktailValueChange}
            cocktail={experiment[EXPERIMENT_INPUTS.cocktails][tabValueCocktail]}
            setElementFocus={setElementFocus}
            studyType={studyType}
            isChipOnly={isChipOnly}
            addButtonLabel="Add a solution"
          >
            <ExperimentDetail
              showDrugs={true}
              cocktail={experiment[EXPERIMENT_INPUTS.cocktails][tabValueCocktail]}
              cocktailValueChange={cocktailValueChange}
              detailKey={COCKTAIL_INPUTS.workingSolution}
              linkedChips={false}
              chips={experiment[EXPERIMENT_INPUTS.chips]}
              heading={`${value + 1}. ${tabs[2]}`}
              tabValue={value}
              studyType={studyType}
              tab={tab}
            />
          </TabWrapper>
        );
      case "Chip with Tissue":
      case "Basal":
        return (
          <TabWrapper
            tabValue={tabValueCocktail}
            handleTabChange={handleTabChangeCocktail}
            arr={experiment[EXPERIMENT_INPUTS.cocktails]}
            handleChange={handleChange}
            value={value}
            heading={`${value + 1}. ${tabs[3]}`}
            setElementFocus={setElementFocus}
            studyType={studyType}
            isChipOnly={isChipOnly}
          >
            <ExperimentDetail
              showDrugs={false}
              cocktail={experiment[EXPERIMENT_INPUTS.cocktails][tabValueCocktail]}
              cocktailValueChange={cocktailValueChange}
              detailKey={COCKTAIL_INPUTS.metabolismCondition}
              linkedChips={true}
              chips={experiment[EXPERIMENT_INPUTS.chips]}
              heading={`${value + 1}. ${tabs[3]}`}
              tabValue={value}
              studyType={studyType}
              tab={tab}
            />
          </TabWrapper>
        );
      case "Chip only":
        return (
          <TabWrapper
            tabValue={tabValueCocktail}
            handleTabChange={handleTabChangeCocktail}
            arr={experiment[EXPERIMENT_INPUTS.cocktails]}
            handleChange={handleChange}
            value={value}
            heading={`${value + 1}. ${tabs[studyType === STUDY_TYPES.Clearence ? 4 : 5]} (optional)`}
            setElementFocus={setElementFocus}
            studyType={studyType}
            isChipOnly={isChipOnly}
            itemDisplayText={studyType === STUDY_TYPES.Clearence ? "" : "Victim Drug"}
          >
            <ExperimentDetail
              showDrugs={false}
              cocktail={experiment[EXPERIMENT_INPUTS.cocktails][tabValueCocktail]}
              cocktailValueChange={cocktailValueChange}
              detailKey={COCKTAIL_INPUTS.nsbCondition}
              linkedChips={true}
              chips={experiment[EXPERIMENT_INPUTS.chips]}
              heading={`${value + 1}. ${tabs[studyType === STUDY_TYPES.Clearence ? 4 : 5]}`}
              tabValue={value}
              studyType={studyType}
              tab={tab}
            />
            <Typography sx={vars.label} variant="body2">
              Optional: use chip only samples when assessing nonspecific binding
            </Typography>
          </TabWrapper>
        );
      case "Induction Pre-Incubation":
      case "Inhibition Pre-Incubation":
        return (
          <TabWrapper
            tabValue={inducerTabValue}
            handleTabChange={handleTabChangeInducer}
            onAdd={addInducer}
            onRemove={removeInducer}
            arr={experiment[EXPERIMENT_INPUTS.inducerdrugs]}
            handleChange={handleChange}
            value={value}
            heading={`${value + 1}. ${tabs[2]}`}
            setElementFocus={setElementFocus}
            studyType={studyType}
            isChipOnly={isChipOnly}
            itemDisplayText={studyType === STUDY_TYPES.Inhibition ? "Inhibition" : "Induction"}
            addButtonLabel={studyType === STUDY_TYPES.Inhibition ? "Add an inhibition solution" : "Add an Induction"}
          >
            <ExperimentDetail
              showDrugs={false}
              cocktail={experiment[EXPERIMENT_INPUTS.inducerdrugs][inducerTabValue]}
              cocktailValueChange={inducerValueChange}
              detailKey={COCKTAIL_INPUTS.perpetrator}
              chips={experiment[EXPERIMENT_INPUTS.chips]}
              heading={`${value + 1}. ${tabs[2]}`}
              tabValue={value}
              studyType={studyType}
              tab={tab}
            />
          </TabWrapper>
        );
      case "Induction Drug & Victim Drug":
      case "Inhibition Drug & Victim Drug":
        return (
          <TabWrapper
            tabValue={tabValueCocktail}
            handleTabChange={handleTabChangeCocktail}
            onAdd={addCocktail}
            onRemove={removeCocktail}
            arr={experiment[EXPERIMENT_INPUTS.cocktails]}
            handleChange={handleChange}
            value={value}
            heading={`${value + 1}. ${tabs[3]}`}
            setElementFocus={setElementFocus}
            studyType={studyType}
            isChipOnly={isChipOnly}
            itemDisplayText={studyType === STUDY_TYPES.Inhibition ? "Victim + Inhibition" : "Victim + Induction"}
            addButtonLabel={studyType === STUDY_TYPES.Inhibition ? "Add a victim + inhibition" : "Add a victim + Induction"}
          >
            <ExperimentDetail
              showDrugs={false}
              cocktail={experiment[EXPERIMENT_INPUTS.cocktails][tabValueCocktail]}
              cocktailValueChange={cocktailValueChange}
              detailKey={COCKTAIL_INPUTS.victimDrug}
              chips={experiment[EXPERIMENT_INPUTS.chips]}
              heading={`${value + 1}. ${tabs[3]}`}
              tabValue={value}
              studyType={studyType}
              experiment={experiment}
              tab={tab}
            />
          </TabWrapper>
        );
      case "Sampling Time":
        return (
          <TabWrapper
            tabValue={tabValueCocktail}
            handleTabChange={handleTabChangeCocktail}
            arr={experiment[EXPERIMENT_INPUTS.cocktails]}
            handleChange={handleChange}
            value={value}
            heading={`${value + 1}. ${tabs[4]}`}
            setElementFocus={setElementFocus}
            studyType={studyType}
            isChipOnly={isChipOnly}
            itemDisplayText={studyType === STUDY_TYPES.Inhibition ? "Victim + Inhibition" : "Victim + Induction"}
          >
            <ExperimentDetail
              showDrugs={false}
              cocktail={experiment[EXPERIMENT_INPUTS.cocktails][tabValueCocktail]}
              cocktailValueChange={cocktailValueChange}
              detailKey={COCKTAIL_INPUTS.metabolismCondition}
              linkedChips={true}
              chips={experiment[EXPERIMENT_INPUTS.chips]}
              heading={`${value + 1}. ${tabs[4]}`}
              tabValue={value}
              studyType={studyType}
              tab={tab}
            />
          </TabWrapper>
        );
      default:
        return null;
    }
  };

  const renderDialogContent = () => {
    if (loading) {
      return <LoadingDialog />;
    }
    if (excelGeneration) {
      return <CompletionDialog handleClose={handleCloseDialog} experiment={experiment} />;
    }
    if (showFieldsValidationDialog) {
      return (
        <FieldsValidationDialog
          setShowFieldsValidationDialog={setShowFieldsValidationDialog}
          missingFields={emptyFields}
          setValue={setValue}
          setOpenCreationDialog={setOpen}
        />
      );
    }

    return (
      <>
        <Box className="bg-gray" display="flex" justifyContent="space-between" alignItems="flex-start" p={3} pb={1.5}>
          <DialogTitle>
            Sample List Generator
            <Typography>To generate a data file template, please fill out the information below.</Typography>
          </DialogTitle>
          <IconButton sx={{ padding: 0 }} aria-label="close" onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={classes.shadow} zIndex={1} className="bg-gray border-bottom" px={3}>
          <Tabs variant="scrollable" className="custom-arrow" value={value} onChange={handleChange}>
            {tabs.map((tab, index) => {
              const tabLabel = `${index + 1}. ${tab}`;
              return index === tabs.length - 1 ? (
                <Tooltip key={index} title="Optional" placement="right" arrow>
                  <Tab className="optional" label={tabLabel} {...a11yProps(index)} disabled={!isChipOnly} />
                </Tooltip>
              ) : (
                <Tab key={index} label={tabLabel} {...a11yProps(index)} />
              );
            })}
          </Tabs>
        </Box>

        <DialogContent className="disableGutter height-full">
          {tabs.map((tab, index) => (
            <TabPanel key={index} value={value} index={index}>
              {renderTabContent(tab)}
            </TabPanel>
          ))}
        </DialogContent>
      </>
    );
  };

  if (Object.keys(experiment).length === 0) {
    return <div>loading</div>;
  }
  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      {renderDialogContent()}
    </Dialog>
  );
}
